#navbar {
  box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);
}

.ramadan {
  background-image: url('../images/ramadan.png');
  background-size: 50%;
  background-repeat: repeat-x;
  height: 39px;
}

// Make navbar full height when opened to allow scroll on tiny screens (or landscape mobile)
.navbar-collapse-shown .fixed-top {
  bottom: 0;
  overflow: scroll;
}

.navbar-brand img {
  height: 1em;
  vertical-align: middle;
  width: auto;
}

.navbar-dark {
  background: transparent;
  border-bottom: 1px solid rgba(0, 0, 0, 0.08);
}

.navbar-brand,
.nav-link {
  text-shadow: 0 1px 1px rgba(0, 0, 0, 0.08);
}

// Nicer colors specifically for fishisfast
.navbar-light .navbar-nav .nav-link {
  color: $gray-500;
}

// Knowledge base dropdowns overflow for different languages
.js-prevent-close {
  min-width: 18rem;
}
.thin-dropdown {
  h6.dropdown-header,
  span.text-truncate.d-inline-block {
    max-width: 10rem;
  }
}

// Address width
.nav-address {
  white-space: nowrap;
  min-width: 12rem;
}

// Mobile navbar
.mobile-toggler {
  margin-right: -1rem;
  font-size: 1.5rem;
  padding-top: 0;
}
.mobile-navbar {
  font-size: 1.3rem;
}

// Logo sizes
img.navbar-logo-mvb {
  height: 1.3em !important;
  width: auto !important;
  vertical-align: middle !important;
}
img.navbar-logo-ff {
  height: 1.5em !important;
  width: 1.5em !important;
  vertical-align: bottom !important;
}
