#welcome {
  color: #30465f;

  #navbar {
    margin-bottom: 0 !important;
    box-shadow: none;

    nav {
      border-bottom: 0;
    }

    .navbar-brand {
      color: $blue-600;
    }
  }

  .nav-link {
    text-shadow: none;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: #30465f;
  }
}

.flag-icon {
  width: 1.5em;
  height: 1.5em;
}

.homeLinks {
  li {
    margin-bottom: 0.1em;
  }

  .mdi {
    font-size: 1.3em;
    display: inline-block;
    width: 1.3em;
    height: 1.3em;
    margin-right: 0.1em;
    line-height: 1em;
  }
}

.hero-wrap {
  overflow: hidden;
}

.hero-logo-group {
  overflow: hidden;
  left: -10vw;
  right: -10vw;
  top: 0;
  bottom: 0;
  text-align: center;
}

.hero-gray-logo {
  height: 2.5rem;
  width: 7rem;
  margin: 2.5rem;
  display: inline-block;
  text-align: center;

  @media (min-width: 576px) {
    height: 2.5rem;
    width: 7rem;
    margin: 2.5rem;
  }
  @media (min-width: 768px) {
    height: 2.5rem;
    width: 7rem;
    margin: 2.5rem;
  }
  @media (min-width: 992px) {
    height: 2.5rem;
    width: 7rem;
    margin: 2.5rem;
  }
  @media (min-width: 1400px) {
    height: 2.5rem;
    width: 7rem;
    margin: 2.5rem;
  }

  svg {
    max-height: 100%;
    max-width: 100%;

    path {
      fill: $gray-300;
    }
  }
}

.gray-logo.card-centered {
  position: absolute;
  height: 7.25vw;
  width: 20vw;
  padding: 0rem;

  @media (min-width: 576px) {
    height: 2.5rem;
    width: 7rem;
  }

  svg path {
    fill: $gray-400;
  }

  &#logo_0 {
    top: 16vw;
    right: 4vw;

    @media (min-width: 576px) {
      top: 4rem;
      right: 3rem;
    }
    @media (min-width: 768px) {
      top: 3rem;
      right: 0rem;
    }
    @media (min-width: 992px) {
      top: 4rem;
      right: 1rem;
    }
    @media (min-width: 1400px) {
      top: 6rem;
      right: 1rem;
    }
  }

  &#logo_1 {
    top: 12vw;
    right: 44vw;

    @media (min-width: 576px) {
      top: 3rem;
      right: 13rem;
    }
    @media (min-width: 768px) {
      top: 3rem;
      right: 10rem;
    }
    @media (min-width: 992px) {
      top: 3rem;
      right: 11rem;
    }
    @media (min-width: 1400px) {
      top: 3rem;
      right: 11rem;
    }
  }

  &#logo_2 {
    top: 32vw;
    right: 72vw;

    @media (min-width: 576px) {
      top: 8rem;
      right: 20rem;
    }
    @media (min-width: 768px) {
      top: 8rem;
      right: 15rem;
    }
    @media (min-width: 992px) {
      top: 8rem;
      right: 18rem;
    }
    @media (min-width: 1400px) {
      top: 8rem;
      right: 21rem;
    }
  }

  &#logo_3 {
    top: 32vw;
    right: -12vw;

    @media (min-width: 576px) {
      top: 8rem;
      right: -3rem;
    }
    @media (min-width: 768px) {
      top: 8rem;
      right: -5rem;
    }
    @media (min-width: 992px) {
      top: 8rem;
      right: -3rem;
    }
    @media (min-width: 1400px) {
      top: 12rem;
      right: -3rem;
    }
  }

  &#logo_4 {
    top: 52vw;
    right: -8vw;

    @media (min-width: 576px) {
      top: 13rem;
      right: -2rem;
    }
    @media (min-width: 768px) {
      top: 13rem;
      right: -4rem;
    }
    @media (min-width: 992px) {
      top: 13rem;
      right: -2rem;
    }
    @media (min-width: 1400px) {
      top: 18rem;
      right: -2rem;
    }
  }

  &#logo_5 {
    top: 56vw;
    right: 84vw;

    @media (min-width: 576px) {
      top: 14rem;
      right: 23rem;
    }
    @media (min-width: 768px) {
      top: 14rem;
      right: 17rem;
    }
    @media (min-width: 992px) {
      top: 14rem;
      right: 21rem;
    }
    @media (min-width: 1400px) {
      top: 14rem;
      right: 27rem;
    }
  }

  &#logo_6 {
    top: 72vw;
    right: -8vw;

    @media (min-width: 576px) {
      top: 18rem;
      right: 0rem;
    }
    @media (min-width: 768px) {
      top: 18rem;
      right: -5rem;
    }
    @media (min-width: 992px) {
      top: 18rem;
      right: -2rem;
    }
    @media (min-width: 1400px) {
      top: 24rem;
      right: -2rem;
    }
  }

  &#logo_7 {
    top: 80vw;
    right: 84vw;

    @media (min-width: 576px) {
      top: 20rem;
      right: 23rem;
    }
    @media (min-width: 768px) {
      top: 20rem;
      right: 18rem;
    }
    @media (min-width: 992px) {
      top: 20rem;
      right: 21rem;
    }
    @media (min-width: 1400px) {
      top: 20rem;
      right: 26rem;
    }
  }

  &#logo_8 {
    top: 109vw;
    right: 10vw;

    @media (min-width: 576px) {
      top: 23rem;
      right: -3rem;
    }
    @media (min-width: 768px) {
      top: 26rem;
      right: -2rem;
    }
    @media (min-width: 992px) {
      top: 23rem;
      right: -5rem;
    }
    @media (min-width: 1400px) {
      top: 30rem;
      right: -5rem;
    }
  }

  &#logo_9 {
    top: 126vw;
    right: 23vw;

    @media (min-width: 576px) {
      top: 33rem;
      right: 4rem;
    }
    @media (min-width: 768px) {
      top: 31rem;
      right: 3rem;
    }
    @media (min-width: 992px) {
      top: 33rem;
      right: 2rem;
    }
    @media (min-width: 1400px) {
      top: 38rem;
      right: 2rem;
    }
  }

  &#logo_10 {
    top: 144vw;
    right: 6vw;

    @media (min-width: 576px) {
      top: 38rem;
      right: 8rem;
    }
    @media (min-width: 768px) {
      top: 35rem;
      right: 10rem;
    }
    @media (min-width: 992px) {
      top: 38rem;
      right: 6rem;
    }
    @media (min-width: 1400px) {
      top: 43rem;
      right: 8rem;
    }
  }

  &#logo_11 {
    top: 104vw;
    right: 96vw;

    @media (min-width: 576px) {
      top: 26rem;
      right: 26rem;
    }
    @media (min-width: 768px) {
      top: 26rem;
      right: 22rem;
    }
    @media (min-width: 992px) {
      top: 26rem;
      right: 24rem;
    }
    @media (min-width: 1400px) {
      top: 26rem;
      right: 25rem;
    }
  }

  &#logo_12 {
    top: 124vw;
    right: 112vw;

    @media (min-width: 576px) {
      top: 31rem;
      right: 30rem;
    }
    @media (min-width: 768px) {
      top: 32rem;
      right: 20rem;
    }
    @media (min-width: 992px) {
      top: 31rem;
      right: 28rem;
    }
    @media (min-width: 1400px) {
      top: 31rem;
      right: 29rem;
    }
  }
}

.shipper-logo-1 {
  top: 0vw;
  right: 59vw;
  width: 35vw;

  @media (min-width: 576px) {
    top: 1rem;
    right: 20rem;
    width: 14rem;
  }
  @media (min-width: 768px) {
    top: 3rem;
    right: 13rem;
    width: 8rem;
  }
  @media (min-width: 992px) {
    top: 3rem;
    right: 18rem;
    width: 10rem;
  }
  @media (min-width: 1400px) {
    top: 4rem;
    right: 22rem;
    width: 12rem;
  }
}

.shipper-logo-2 {
  top: 7vw;
  right: 0vw;
  width: 42vw;

  @media (min-width: 576px) {
    top: 0rem;
    right: 0rem;
    width: 15rem;
  }
  @media (min-width: 768px) {
    top: 0rem;
    right: 0rem;
    width: 10rem;
  }
  @media (min-width: 992px) {
    top: 1rem;
    right: 0rem;
    width: 12rem;
  }
  @media (min-width: 1400px) {
    top: 2rem;
    right: 0rem;
    width: 13rem;
  }
}

.shipper-logo-3 {
  top: 68vw;
  right: 56vw;
  width: 30vw;

  @media (min-width: 576px) {
    top: 19rem;
    right: 20rem;
    width: 13rem;
  }
  @media (min-width: 768px) {
    top: 14rem;
    right: 11rem;
    width: 9rem;
  }
  @media (min-width: 992px) {
    top: 19rem;
    right: 16rem;
    width: 11rem;
  }
  @media (min-width: 1400px) {
    top: 26rem;
    right: 20rem;
    width: 11rem;
  }
}

.shipper-logo-4 {
  top: 70vw;
  right: 0vw;
  width: 45vw;

  @media (min-width: 576px) {
    top: 24rem;
    right: 0rem;
    width: 16rem;
  }
  @media (min-width: 768px) {
    top: 17rem;
    right: 0rem;
    width: 9rem;
  }
  @media (min-width: 992px) {
    top: 22rem;
    right: 2rem;
    width: 10rem;
  }
  @media (min-width: 1400px) {
    top: 23rem;
    right: 0rem;
    width: 14rem;
  }
}
