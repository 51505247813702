// Wide locker
#users.show > .container-fluid {
  max-width: none;
}

//  Copy icon on address
.copy-icon {
  cursor: pointer;
}

#copy-notification {
  position: fixed;
  top: 1.5rem;
  left: 50%;
  transform: translateX(-50%);
  background-color: #333;
  color: #fff;
  padding: 10px;
  border-radius: 5px;
  opacity: 0;
  transition: opacity 0.5s ease-in-out;
  z-index: 1000;
}

#copy-notification.show {
  opacity: 1;
}
