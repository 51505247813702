// Add CSS here if you need to, and we'll sort it later :)
.btn-danger a {
  color: $white;
}

// Make inputs not have padding on the right to make it more obvious there is overflowing text:
.form-control {
  padding-right: 0;
}

// Avatar
img.avatar {
  line-height: 1em;
  vertical-align: middle;
  height: 1.6rem;
  width: 1.6rem;
  min-height: 1.6rem;
  min-width: 1.6rem;
  background-color: rgba(255, 255, 255, 0.3);
}

// Color store logo svgs
.svg {
  &.fill-white svg path {
    fill: $white;
  }
  &.fill-secondary svg path {
    fill: theme-color('secondary');
  }
}

// Bold headers
h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: bold;
}

// New h1 header sizes
h1 {
  font-size: 2rem;

  @media (min-width: 768px) {
    font-size: 2.4rem;
  }
}

// Large paragraphs for marketing
p.p-lg,
.p-lg p {
  font-size: 1.0625rem;
  line-height: 1.65em;

  @media (min-width: 768px) {
    font-size: 1.5rem;
  }
}

.ocean {
  background: linear-gradient(
    180deg,
    $blue-800,
    $blue-700,
    $blue-600,
    $blue-500,
    $blue-400,
    $blue-300,
    $blue-200
  );
}

// Styling of buttons
.btn {
  border-color: rgba(0, 0, 0, 0.05) !important;
  transition: transform 0.15s;

  &:hover {
    transform: translateY(-1px);
  }
  &:active {
    transform: translateY(1px);
  }
}

// Extra margin rules
div:not(.btn-group) > .btn:not(:only-of-type) {
  margin: 0.25rem;
}

.btn {
  white-space: normal;
  box-shadow: 0 2px 3px rgba(0, 0, 0, 0.1);
  &:hover {
    box-shadow: 0 3px 3px rgba(0, 0, 0, 0.1);
  }
  &:active {
    box-shadow: 0 2px 3px rgba(0, 0, 0, 0.1);
  }
  &:focus {
    box-shadow: 0 0 0 0.2rem rgba(30, 164, 215, 0.25),
      0 2px 3px rgba(0, 0, 0, 0.1);
  }
}

@each $color, $value in $theme-colors {
  .btn-#{$color} {
    &:hover {
      background: $value;
      border-color: $value;
    }
  }
}

.btn-light,
.btn-light:hover {
  color: theme-color('secondary');
}

.btn-info,
.btn-info:hover {
  color: darken(theme-color('info'), 40%);
}

.btn-warning,
.btn-warning:hover {
  color: darken(theme-color('warning'), 60%);
}

// Navbar spacing stuff and extra padding
.navbar-brand img {
  height: 1em;
  vertical-align: middle;
  width: auto;
}

// Navbar highlighting red dot
.icon-highlight {
  position: relative;
  &:after {
    content: '';
    background: #dd3300;
    width: 0.5em;
    height: 0.5em;
    top: 0.1em;
    z-index: 9999;
    right: 0.1em;
    border-radius: 1em;
    display: inline-block;
    position: absolute;
  }
}

// Hide caret optionally
.dropdown-toggle.dropdown-toggle-no-caret::after {
  display: none;
}

// Making labels nicer in navbars.
.navbar-dark .navbar-nav .nav-link .label-light {
  color: $blue;
  background: rgba(255, 255, 255, 0.5);
}
.navbar-dark .navbar-nav .active > .nav-link .label-light {
  background: $white;
}
.navbar-light .navbar-nav .nav-link .label-light {
  color: $white;
  background: rgba(0, 0, 0, 0.5);
}
.navbar-light .navbar-nav .active > .nav-link .label-light {
  background: rgba(0, 0, 0, 0.9);
}

// Make cards and stuff look cooler
.breadcrumb,
.well,
.card,
.quote,
.help,
.popover,
.dropdown-menu,
.user-avatar,
#flash .alert,
.modal-content,
.shadow {
  box-shadow: 0 0.1rem 0.3rem rgba(0, 0, 0, 0.1);
  border: none;

  .breadcrumb,
  .well,
  .card,
  .quote,
  .help,
  .popover,
  .dropdown-menu,
  .user-avatar,
  #flash .alert,
  .modal-content,
  .shadow {
    box-shadow: none;
    border: 1px solid #d9dbde;
  }
}
.card,
.dropdown-menu,
.breadcrumb {
  border-radius: 0.5rem;
  background: $white;
}

// Hide corners of full-card content (border-radius)
.card {
  overflow: hidden;
}

.card-hover {
  cursor: pointer;
  transition: all 0.15s;

  &:hover {
    transform: translateY(-0.2rem) scale(1.02);
    box-shadow: 0 15px 35px rgba(50, 50, 93, 0.1),
      0 5px 15px rgba(0, 0, 0, 0.07);
  }
}

// Make a card type that retains aspect ratio
.card-centered {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2rem;
  height: 6rem;

  // Keep shop logos of random sizes in relatively nice proportions to each other
  > svg {
    height: auto;
    width: auto;
    /*max-height: 100%;*/
    max-width: 100%;

    path {
      fill: #ffffff;
    }
  }

  .fastbuy-badge {
    position: absolute;
    top: 0;
    left: 0;
    font-size: 0.7rem;
    border-radius: 4px 0px;
  }
}

// About page timeline
.timeline {
  position: relative;
  padding-left: 2rem;
  overflow: auto;

  &:before {
    content: '';
    background: $gray-200;
    position: absolute;
    top: 0.5rem;
    left: 0.9rem;
    width: 0.2rem;

    bottom: 0;
  }

  &:after {
    content: '';
    background: $gray-200;
    position: absolute;
    top: 0;
    left: 0.45rem;
    width: 1.1rem;

    height: 1.1rem;
    border: solid 0.2rem white;
    border-radius: 1rem;
  }
}

// We use this everywhere, it is very important.
.hide,
.hidden {
  display: none;
}

// The speechbubble quotes and their avatars, names and links
.speechbubble {
  .quote {
    position: relative;
    background: #fff;
    border-radius: 4px;
    font-size: 18px;
    padding: 20px;
    margin: 0 0 30px;
    color: $gray-700;
    border: 1px solid $gray-500;
    font-weight: normal;

    &:after {
      content: '';
      position: absolute;
      border-style: solid;
      border-width: 15px 15px 0;
      border-color: white transparent;
      display: block;
      width: 0;
      z-index: 1;
      bottom: -15px;
      left: 32px;
    }

    &:before {
      content: '';
      position: absolute;
      border-style: solid;
      border-width: 16px 16px 0;
      border-color: #e3e3e3 transparent;
      display: block;
      width: 0;
      z-index: 0;
      bottom: -17px;
      left: 31px;
    }
  }

  &.help-quote .quote {
    background: $gold-300;
    color: $gold-800;
    border-color: $gold-400 !important;

    &:after {
      border-color: $gold-300 transparent;
    }

    &:before {
      border-color: $gold-400 transparent;
    }
  }

  .user {
    display: block;
    overflow: hidden;
    margin-bottom: 40px;
  }

  .user-photo {
    height: 100%;
  }

  .user-avatar {
    width: 50px;
    height: 50px;
    border-radius: 50px;
    float: left;
    margin-left: 22px;
  }

  .user-info {
    font-size: 15px;
    float: left;
    margin-left: 16px;
    margin-top: 2px;
  }
}

// Floating up and down animation - applied to .floater
@keyframes floater {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-3px);
  }
  100% {
    transform: translateY(0);
  }
}

.floater {
  animation: floater 1s ease infinite;
}

// Spinning animation - PRV
@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.spin:before {
  animation: spin 1s linear infinite;
}

// Make labels have a bold font and different padding for Avenir to look hot and slightly smaller
.badge,
.label {
  border-radius: 5em;
}

// FIXME Marc, did you know I love you more than my son?
.blindingly-obvious {
  border: 5px solid theme-color('success');
}

// Apply this class to any tracking number that might mess up by going out of it's container, this breaks it
.tracking_number {
  word-break: break-all;
}

// <j> wrap is used in Japanese on the site to make sure words don't break halfway through
j {
  display: inline-block;
}

// Navbar spacing stuff and extra padding - PRV
.navbar-brand {
  img {
    height: 1em;
    vertical-align: middle;
    width: auto;
  }
}

// Making labels nicer in navbars.
.navbar-dark .navbar-nav .nav-link .label-light {
  color: $blue;
  background: rgba(255, 255, 255, 0.5);
}

.navbar-dark .navbar-nav .active > .nav-link .label-light {
  background: $white;
}

.navbar-light .navbar-nav .nav-link .label-light {
  color: $white;
  background: rgba(0, 0, 0, 0.5);
}

.navbar-light .navbar-nav .active > .nav-link .label-light {
  background: rgba(0, 0, 0, 0.9);
}

// FIXME UNFILTERED CSS FROM HERE

// The amazing .well-title puts a windows/mac like bar on top of a .well element - PRV
.well-title {
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  margin: -20px -20px 20px;
  padding: 15px 20px 10px;
  border-top-left-radius: 0.2rem;
  border-top-right-radius: 0.2rem;

  select {
    margin-top: 3px;
  }
}

// Padding is slightly different when the well-title is only an h4 without other elements - PRV
h4 {
  &.well-title {
    padding: 27px 20px 21px;
  }
}

// Add some border to the top of the wells to add more contrast - PRV
.well {
  .well {
    border-color: $gray-400 !important;
    box-shadow: none !important;
  }
}

.nav-tabs > li.active > a {
  border-top-color: #e6e6e6;
  border-left-color: #e6e6e6;
  border-right-color: #e6e6e6;
}

// Style fix for VK button - PRV
#vkshare tr td a {
  height: 14px !important;
}

// Packages
.consolidation.well {
  position: relative;
  margin-bottom: 30px;

  address {
    margin-bottom: 0;
  }

  .well-title {
    a:not(.btn) {
      color: $gray-800;

      &:hover {
        text-decoration: none;
      }
    }
  }

  .well-dropdown {
    padding: 0;

    .well-title {
      margin: 0;
    }

    .well-collapse-wrap {
      padding: 20px;
    }
  }
}

// Mobile counter badges on the menubar icons
.badge-mobile-corner {
  font-size: 10px;
  top: 7px;
  left: 18px;
  position: absolute;
}
.badge-mobile-anchor {
  position: relative;
}

// Cute progress indicators, aaahw - PRV
.progress-dots {
  margin-top: 7px;
  margin-bottom: -7px;

  .dot {
    width: 15px;
    height: 15px;
    border: 3px solid theme-color('secondary');
    border-radius: 15px;
    display: inline-block;

    &.active {
      background-color: theme-color('secondary');
      border-color: transparent;
    }
  }
}

// Hide the little border underneath the required input asterisk - PRV
abbr[title],
abbr[data-original-title] {
  border-bottom: 0;
}

// Flash alerts styling
#flash {
  position: fixed;
  z-index: 1045;
  top: 1rem;
  left: 1rem;
  right: 1rem;

  @media (min-width: 768px) {
    max-width: 25rem;
    padding: 0;

    top: 60px;
    left: 50%;
    right: inherit;
    transform: translateX(-50%);
  }

  .alert {
    padding-right: 3rem;
  }
}

.tooltip-inner {
  padding: 5px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1) !important;
}

.tooltip.show {
  opacity: 1;
}

.alert-bigger {
  font-size: 1.4em;
}

.no-border {
  border: 0 !important;
}

.team {
  .row {
    margin-bottom: 10px;
  }
}

div#stores {
  padding: 5vh 0;
  background: $gray-600;
}

.user_remember_me {
  position: relative;
}

// Spinning animation - PRV
@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.spin {
  animation: spin 1s linear infinite;
  width: 17px;
  height: 17px;
  display: inline-block;

  &::before {
    line-height: 0;
    font-size: 1em;
    vertical-align: baseline;
  }
}

.tooltip-wrapper {
  display: inline-block;

  .btn {
    :disabled {
      pointer-events: none;
    }
  }

  &.disabled {
    cursor: not-allowed;
  }
}

// CSS image of an iPad - PRV
.ipad {
  border: 2px solid #bbb;
  border-radius: 1.4em;
  width: 20.3em;
  height: 30em;
  padding: 1.2em 1.1em;
  background: #fff;
  position: absolute;
  right: 3em;
  bottom: -1em;

  .camera {
    border: 1px solid #bbb;
    border-radius: 4em;
    width: 0.5em;
    height: 0.5em;
    margin: 0 auto;
  }

  .content {
    border: 1px solid #bbb;
    margin-top: 1.2em;
    height: 24em;
    overflow: hidden;
    position: relative;
  }

  .mobile-order {
    position: absolute;
    bottom: 46px;
    left: 9px;
    width: 160px;
  }

  .homebutton {
    border: 1px solid #bbb;
    border-radius: 4em;
    width: 1.7em;
    height: 1.7em;
    margin: 0.5em auto;
  }
}

// CSS image of an iPhone - PRV
.iphone {
  border: 2px solid #bbb;
  border-radius: 1.5em;
  width: 9em;
  height: 18.85em;
  padding: 1em 0.4em;
  background: #fff;
  position: absolute;
  right: 0;
  bottom: -1em;

  .camera {
    border: 1px solid #bbb;
    border-radius: 4em;
    width: 0.35em;
    height: 0.35em;
    float: left;
    margin-left: 2.5em;
    margin-right: 0.55em;
  }

  .speaker {
    border: 1px solid #bbb;
    border-radius: 1em;
    width: 1.5em;
    height: 0.25em;
    float: left;
    margin-top: 0.05em;
  }

  .content {
    border: 1px solid #bbb;
    margin-top: 1.2em;
    height: 14.1em;
    overflow: hidden;
    position: relative;
  }

  .mobile-order {
    position: absolute;
    bottom: 2.3em;
    left: 0.45em;
    width: 8em;
  }

  .homebutton {
    border: 1px solid #bbb;
    border-radius: 4em;
    width: 1.35em;
    height: 1.35em;
    margin: 0.35em auto;
  }
}

// CSS image of a browser - PRV
.cssbrowser {
  border: 2px solid #bbb;
  border-top-left-radius: 7px;
  border-top-right-radius: 7px;
  font-size: 14px;
  line-height: 19px;
  position: relative;
  background: #fff;

  a {
    text-decoration: none;
  }

  .address {
    border: 1px solid #bbb;
    border-radius: 5px;
    display: inline-block;
    height: 25px;
    font-size: 12px;
    left: 65px;
    padding: 3px 6px;
    position: absolute;
    right: 7px;
    color: #bbb;
  }

  .brbtn {
    border: 1px solid #bbb;
    color: #bbb;
    cursor: pointer;
    display: inline-block;
    height: 25px;
    margin-right: -5px;
    padding: 2px 7px;
    width: 26px;
    transition: all 0.15s;
  }

  .back {
    border-bottom-left-radius: 5px;
    border-top-left-radius: 5px;
  }

  .content {
    position: relative;
    overflow: hidden;

    .contentMenu,
    .contentTop {
      line-height: 0;
    }
  }

  .forward {
    border-bottom-right-radius: 5px;
    border-top-right-radius: 5px;
    border-left: 0;
  }

  .toolbar {
    border-bottom: 1px solid #bbb;
    border-top-left-radius: 7px;
    border-top-right-radius: 7px;
    height: 40px;
    padding: 7px;
  }

  &.mail {
    .infofield {
      background: #f9f9f9;
      padding: 15px 20px;
      overflow: hidden;

      h5 {
        margin: 5px 0 !important;
      }
    }

    .brbtn {
      border-radius: 4px;
      margin-right: 0;
    }
  }
}

.codeimage {
  position: relative;
  font-size: 10px;
}

// Bootstrap issue workaround. Check this: https://github.com/twbs/bootstrap/issues/23454
.invalid-feedback {
  display: block;
}

// Codeblocks that are direct children of a card should not have margins
.card > pre {
  margin: 0;
}

// Disabled Shipping Method
.disabled-method {
  opacity: 0.5;
}

.filter-btn {
  margin-left: 5px;
}

.br-custom-weight {
  width: 100%;
  @media (min-width: 768px) {
    width: 40%;
  }
  margin-bottom: 0;
}

.current_parcel_preferred_carrier_weight {
  width: 50%;
  margin-bottom: 0;
  .js-preferred-carrier-weight {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
}

.preferred-shipper-radio ul {
  margin-top: 8px;
  margin-bottom: 8px;
}

.preferred-shipper-radio label {
  margin-bottom: 0;
  padding: 8px 12px;
  border: 1px solid #ccc;
  border-radius: 5px;
  cursor: pointer;
}

.preferred-shipper-radio .custom-control-input ~ .custom-control-label::before, .preferred-shipper-radio .custom-control-input ~ .custom-control-label::after {
  // center them vertically; Some weird padding at the top requires 42 instead of 50
  top: 42%;
}

.preferred-shipper-radio label:hover {
  background-color: #f0f0f0;
}

.preferred-shipper-radio input[type="radio"]:checked + label {
  background-color: $green;
  color: #fff;
}

.panel-disabled {
   opacity: 0.5;
 }

// Remove the class after upgrading to Bootstrap v5
.pe-none {
  pointer-events: none;
}
