// Fonts
@import 'fonts';

// Bootstrap variable overrides
@import 'variables';

// Bootstrap
@import '~bootstrap/scss/bootstrap';

// Datepicker
@import 'vendor/bootstrap-datepicker3';

// Bootstrap 3 backward compatibility
@import 'compatibility';

/*! rtl:begin:ignore */

// Special select dropdown
@import '~select2';

/*! rtl:end:ignore */

@import '~@ttskch/select2-bootstrap4-theme/src/select2-bootstrap4';

// General overrides
@import 'overrides';
@import 'inputs';

// Our stuff
@import 'navigation';
@import 'locker';
@import 'welcome';
@import 'cookies_banner';

// Catch-all for more unfiltered CSS
@import 'hacks';

// simple_form helpers
@import 'vendor/simple_form-bootstrap/simple_form-bootstrap';
