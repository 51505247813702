.cookies-banner {
  display: flex;
  flex-direction: row;
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 1em 1.8em;
  width: 100%;
  -webkit-box-direction: normal;
  -webkit-box-align: center;
  align-items: center;
  color: rgb(255, 255, 255);
  background-color: rgb(34, 51, 68);
  z-index: 9999;

  .cookies-message {
    display: block;
    max-width: 100%;
    margin-right: 1em;
    font-size: 1.1em;
    line-height: 1.5em;
  }

  .cookies-actions {
    display: flex;
    margin-left: auto;
    margin-right: 5em;
    align-items: center;
    align-content: space-between;

    .cookies-essential-only-btn {
      color: rgb(255, 255, 255);
      background-color: transparent;
      border-color: transparent;

      text-decoration: none;
      border-radius: 5px;

      display: block;
      padding: .4em .8em;
      font-weight: 700;
      border-width: 2px;
      border-style: solid;
      text-align: center;
      white-space: nowrap;
    }

    .cookies-essential-only-btn:hover {
      text-decoration: underline;
    }

    .cookies-accept-all-btn {
      color: rgb(34, 51, 68);
      border-color: transparent;
      background-color: rgb(255, 255, 255);

      text-decoration: none;
      border-radius: 5px;

      display: block;
      padding: .4em .8em;
      font-weight: 700;
      border-width: 2px;
      border-style: solid;
      text-align: center;
      white-space: nowrap;
    }
  }
}
