// optional vertical & horizontal form spacing
.form-check-inline {
  .form-check-label {
    &.collection_check_boxes,
    &.collection_radio_buttons {
      margin-right: 0.265625rem;
    }
  }
}

// optional custom form spacing
.custom-control-inline {
  .custom-control-label {
    &.collection_check_boxes,
    &.collection_radio_buttons {
      margin-right: 0.265625rem;
    }
  }
}

// required custom form radio button fix
.form-group.radio_buttons {
  > .custom-control {
    @extend .custom-radio;
  }
}

// required custom form check box fix
.form-group.check_boxes {
  > .custom-control {
    @extend .custom-checkbox;
  }
}
