@font-face {
  font-family: 'Proxima Nova W07';
  font-weight: 400;
  src: url('../fonts/Proxima Nova Normal.woff2') format('woff2'),
    url('../fonts/Proxima Nova Normal.woff') format('woff');
}
@font-face {
  font-family: 'Proxima Nova W07';
  font-weight: 600;
  src: url('../fonts/Proxima Nova Bold.woff2') format('woff2'),
    url('../fonts/Proxima Nova Bold.woff') format('woff');
}

@font-face {
  font-family: 'BPG 2017 DejaVu Sans';
  font-weight: 400;
  src: url('../fonts/Deja Vu Sans Normal.woff2') format('woff2'),
    url('../fonts/Deja Vu Sans Normal.woff') format('woff');
}
@font-face {
  font-family: 'BPG 2017 DejaVu Sans';
  font-weight: 600;
  src: url('../fonts/Deja Vu Sans Bold.woff2') format('woff2'),
    url('../fonts/Deja Vu Sans Bold.woff') format('woff');
}

// Material Design Icons as SVGs formatted to look like old icon font
svg[id^='mdi-'],
svg.svg-icon {
  fill: currentcolor;
  height: 1em;
  width: 1em;
  pointer-events: none;
  display: inline-block;
}
svg.large {
  height: 1.3em;
  width: 1.3em;
}
// Temp hack for spinning indicator on old JS
@keyframes spinning {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(359deg);
  }
}
.spinning {
  display: inline-block;
  animation: spinning 2s infinite linear;
}
