// General Overrides (keep to miniumum or delete)
.container-fluid {
  max-width: 1400px;
}
.locker-modal-nav-wrapper {
  margin-top: 5.4rem;
}
.locker-modal-nav-back {
  top: -3.5rem;
  left: 0;
  position: absolute;
}
.locker-modal-nav-forward {
  top: -3.5rem;
  right: 0;
  position: absolute;
}
.page.gap {
  flex: 1;
}
.dropdown-item a {
  color: $gray-700;
}
.dropdown-item a:hover,
a:hover {
  text-decoration: none;
}
.nav-tabs .active.nav-item {
  background: white;
}
.well-title h4,
h4.well-title {
  font-size: 1.2rem;
}
.well {
  margin-bottom: 2rem;
}
.label,
.badge {
  border-radius: 0.6rem;
  white-space: normal;
  text-align: left;
  transition: none;
}
.badge-light {
  background: rgba(255, 255, 255, 0.2);
  color: inherit;
}
.badge-dark {
  background: rgba(0, 0, 0, 0.2);
  color: inherit;
}
.badge-secondary {
  background-color: #dee6f1;
  color: #688bb6;
}
.card-header {
  background: transparent;
}
.btn-secondary,
.btn-default {
  @extend .btn-light;
}
.navbar-toggler {
  border: none;
}
.fast_buy_private_address_message {
  background-color: #faf2d8 !important;
}

.add-line-btn {
  margin-bottom: 1.5rem;
}

.addon-description {
  margin-bottom: 0;
}

.item-details-and-customs-line {
  margin-top: -5px;
  margin-bottom: -5px;
}

.customs-info-line {
  padding: 12px;
}

// Intercom overlaps modals
.modal-dialog {
  margin-bottom: 100px;
}

// FIXME error fields in simple_form should not need this extra wrap in input-groups
.input-group .field_with_errors {
  display: flex;
}

// Force the Guide articles to use the proper fonts, no matter what the staff defines
html[lang='ar'] body#knowledge_base .card {
  span,
  a,
  p {
    font-family: $font-family-sans-serif !important;
    font-size: $font-size-base !important;
  }
}

// Font rendering and legibility fixes
body {
  -webkit-text-size-adjust: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
}

// Make selection of text light up in fishisfast color blue
::selection {
  background: $blue-800;
  color: white;
}

// Force all these elements to have our custom font
button,
input,
optgroup,
select,
textarea {
  font-family: $font-family-sans-serif;
}

// Force there to always be a pointy hand cursor on interactive stuff
a,
.btn,
button {
  cursor: pointer;
  cursor: hand;
}

// Forces all images (and svgs) to stay within their containers
img,
svg {
  max-width: 100%;
  height: auto;
}

// Make all link items bold text
a {
  font-weight: bold;
  &:hover {
    text-decoration: none;
  }
}

// Trigger monospace fonts
.text-monospace {
  // font-family: $font-family-monospace;
}

// Text light and dark should be transparent instead
.text-warning {
  color: $gold-800 !important;
  padding: 0px 2px;
}

// Transparent background color
.bg-transparent {
  background: transparent !important;
}

// Custom File Uploaders I18n translations
.custom-file-control {
  overflow: hidden;
  &:empty::after {
    content: 'No file selected';
  }
  &::before {
    content: 'Choose';
  }
  &:lang(ar) {
    &:empty::after {
      content: 'لم يتم اختيار اي ملف';
    }
    &::before {
      content: 'اختر';
    }
  }
  &:lang(en) {
    &:empty::after {
      content: 'No file selected';
    }
    &::before {
      content: 'Choose';
    }
  }
  &:lang(es) {
    &:empty::after {
      content: 'Ningún archivo seleccionado';
    }
    &::before {
      content: 'Elegir';
    }
  }
  &:lang(fr) {
    &:empty::after {
      content: 'Aucun fichier sélectionné';
    }
    &::before {
      content: 'Choisir';
    }
  }
  &:lang(it) {
    &:empty::after {
      content: 'Nessun file selezionato';
    }
    &::before {
      content: 'Scegliere';
    }
  }
  &:lang(ja) {
    &:empty::after {
      content: 'ファイルが選択されていません';
    }
    &::before {
      content: '選ぶ';
    }
  }
  &:lang(ka) {
    &:empty::after {
      content: 'აილი არ არის არჩეული';
    }
    &::before {
      content: 'არჩევა';
    }
  }
  &:lang(ko) {
    &:empty::after {
      content: '파일이 선택되지 않았습니다';
    }
    &::before {
      content: '선택';
    }
  }
  &:lang(pt-BR) {
    &:empty::after {
      content: 'Nenhum arquivo selecionado';
    }
    &::before {
      content: 'Escolher';
    }
  }
  &:lang(ro) {
    &:empty::after {
      content: 'Niciun fisier selectat';
    }
    &::before {
      content: 'Alege';
    }
  }
  &:lang(ru) {
    &:empty::after {
      content: 'Файл не выбран';
    }
    &::before {
      content: 'Выберите';
    }
  }
  &:lang(uk-UA) {
    &:empty::after {
      content: 'No file selected';
    }
    &::before {
      content: 'Choose';
    }
  }
  &:lang(zh-CN) {
    &:empty::after {
      content: '沒有選擇文件';
    }
    &::before {
      content: '選擇';
    }
  }
}

// FIXME track https://github.com/twbs/bootstrap/issues/24800
.modal-footer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-wrap: wrap;
  padding: $modal-inner-padding - $modal-footer-margin-between / 2;
  border-top: $modal-footer-border-width solid $modal-footer-border-color;
  > * {
    margin: $modal-footer-margin-between / 2;
  }
}

// This is because position: fixed doesn't work when a parent is transformed in Chrome/Safari https://bugs.chromium.org/p/chromium/issues/detail?id=20574&desc=2
.modal.show .modal-dialog {
  -webkit-transform: none;
  transform: none;
}

// Class for text to always be LTR (like addresses)
.direction-ltr {
  text-align: inherit #{'/*rtl:left*/'};
  direction: inherit #{'/*rtl:ltr*/'};
  unicode-bidi: inherit #{'/*rtl:embed*/'};
}

// Start Popovers RTL override
.popover {
  right: inherit #{'/*rtl:initial*/'};
  left: inherit #{'/*rtl:0*/'};
}
.bs-popover-right,
.bs-popover-auto[x-placement^='right'] {
  margin-right: auto #{'/*rtl:auto*/'};
  margin-left: 0.5rem #{'/*rtl:0.5rem*/'};
}
.bs-popover-left,
.bs-popover-auto[x-placement^='left'] {
  margin-right: 0.5rem #{'/*rtl:0.5rem*/'};
  margin-left: auto #{'/*rtl:auto*/'};
}
.bs-popover-right > .arrow,
.bs-popover-auto[x-placement^='right'] > .arrow {
  right: auto #{'/*rtl:auto*/'};
  left: calc((0.5rem + 1px) * -1) #{'/*rtl:calc((0.5rem + 1px) * -1)*/'};
}
.bs-popover-left > .arrow,
.bs-popover-auto[x-placement^='left'] > .arrow {
  right: calc((0.5rem + 1px) * -1) #{'/*rtl:calc((0.5rem + 1px) * -1)*/'};
  left: auto #{'/*rtl:auto*/'};
}
.bs-popover-right > .arrow,
.bs-popover-auto[x-placement^='right'] > .arrow {
  transform: auto #{'/*rtl:scaleX(-1)*/'};
}
.bs-popover-left > .arrow,
.bs-popover-auto[x-placement^='left'] > .arrow {
  transform: auto #{'/*rtl:scaleX(-1)*/'};
}
// End Popovers RTL override
