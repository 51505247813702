/*!
 * Datepicker for Bootstrap v1.9.0 (https://github.com/uxsolutions/bootstrap-datepicker)
 *
 * Licensed under the Apache License v2.0 (http://www.apache.org/licenses/LICENSE-2.0)
 */

.datepicker {
  border-radius: 4px;
  direction: ltr;
}

.datepicker-inline {
  width: 220px;
}

.datepicker-rtl {
  direction: rtl;
  &.dropdown-menu {
    left: auto;
  }
  table tr td span {
    float: right;
  }
}

.datepicker-dropdown {
  top: 0;
  left: 0;
  padding: 4px;
  &:before {
    content: '';
    display: inline-block;
    border-left: 7px solid transparent;
    border-right: 7px solid transparent;
    border-bottom: 7px solid rgba(0, 0, 0, 0.15);
    border-top: 0;
    border-bottom-color: rgba(0, 0, 0, 0.2);
    position: absolute;
  }
  &:after {
    content: '';
    display: inline-block;
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    border-bottom: 6px solid #fff;
    border-top: 0;
    position: absolute;
  }
  &.datepicker-orient-left {
    &:before {
      left: 6px;
    }
    &:after {
      left: 7px;
    }
  }
  &.datepicker-orient-right {
    &:before {
      right: 6px;
    }
    &:after {
      right: 7px;
    }
  }
  &.datepicker-orient-bottom {
    &:before {
      top: -7px;
    }
    &:after {
      top: -6px;
    }
  }
  &.datepicker-orient-top {
    &:before {
      bottom: -7px;
      border-bottom: 0;
      border-top: 7px solid rgba(0, 0, 0, 0.15);
    }
    &:after {
      bottom: -6px;
      border-bottom: 0;
      border-top: 6px solid #fff;
    }
  }
}

.datepicker table {
  margin: 0;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  tr {
    td,
    th {
      text-align: center;
      width: 30px;
      height: 30px;
      border-radius: 4px;
      border: none;
    }
  }
}

.table-striped .datepicker table tr {
  td,
  th {
    background-color: transparent;
  }
}

.datepicker table tr td {
  &.old,
  &.new {
    color: #777777;
  }
  &.day:hover,
  &.focused {
    background: #eeeeee;
    cursor: pointer;
  }
  &.disabled {
    background: none;
    color: #777777;
    cursor: default;
    &:hover {
      background: none;
      color: #777777;
      cursor: default;
    }
  }
  &.highlighted {
    color: #000;
    background-color: #d9edf7;
    border-color: #85c5e5;
    border-radius: 0;
    &:focus,
    &.focus {
      color: #000;
      background-color: #afd9ee;
      border-color: #298fc2;
    }
    &:hover,
    &:active,
    &.active {
      color: #000;
      background-color: #afd9ee;
      border-color: #52addb;
    }
    &:active:hover,
    &.active:hover,
    &:active:focus,
    &.active:focus,
    &:active.focus,
    &.active.focus {
      color: #000;
      background-color: #91cbe8;
      border-color: #298fc2;
    }
    &.disabled:hover,
    &[disabled]:hover {
      background-color: #d9edf7;
      border-color: #85c5e5;
    }
  }
}

fieldset[disabled] .datepicker table tr td.highlighted:hover {
  background-color: #d9edf7;
  border-color: #85c5e5;
}

.datepicker table tr td.highlighted {
  &.disabled:focus,
  &[disabled]:focus {
    background-color: #d9edf7;
    border-color: #85c5e5;
  }
}

fieldset[disabled] .datepicker table tr td.highlighted:focus {
  background-color: #d9edf7;
  border-color: #85c5e5;
}

.datepicker table tr td.highlighted {
  &.disabled.focus,
  &[disabled].focus {
    background-color: #d9edf7;
    border-color: #85c5e5;
  }
}

fieldset[disabled] .datepicker table tr td.highlighted.focus {
  background-color: #d9edf7;
  border-color: #85c5e5;
}

.datepicker table tr td {
  &.highlighted {
    &.focused {
      background: #afd9ee;
    }
    &.disabled {
      background: #d9edf7;
      color: #777777;
      &:active {
        background: #d9edf7;
        color: #777777;
      }
    }
  }
  &.today {
    color: #000;
    background-color: #ffdb99;
    border-color: #ffb733;
    &:focus,
    &.focus {
      color: #000;
      background-color: #ffc966;
      border-color: #b37400;
    }
    &:hover,
    &:active,
    &.active {
      color: #000;
      background-color: #ffc966;
      border-color: #f59e00;
    }
    &:active:hover,
    &.active:hover,
    &:active:focus,
    &.active:focus,
    &:active.focus,
    &.active.focus {
      color: #000;
      background-color: #ffbc42;
      border-color: #b37400;
    }
    &.disabled:hover,
    &[disabled]:hover {
      background-color: #ffdb99;
      border-color: #ffb733;
    }
  }
}

fieldset[disabled] .datepicker table tr td.today:hover {
  background-color: #ffdb99;
  border-color: #ffb733;
}

.datepicker table tr td.today {
  &.disabled:focus,
  &[disabled]:focus {
    background-color: #ffdb99;
    border-color: #ffb733;
  }
}

fieldset[disabled] .datepicker table tr td.today:focus {
  background-color: #ffdb99;
  border-color: #ffb733;
}

.datepicker table tr td.today {
  &.disabled.focus,
  &[disabled].focus {
    background-color: #ffdb99;
    border-color: #ffb733;
  }
}

fieldset[disabled] .datepicker table tr td.today.focus {
  background-color: #ffdb99;
  border-color: #ffb733;
}

.datepicker table tr td {
  &.today {
    &.focused {
      background: #ffc966;
    }
    &.disabled {
      background: #ffdb99;
      color: #777777;
      &:active {
        background: #ffdb99;
        color: #777777;
      }
    }
  }
  &.range {
    color: #000;
    background-color: #eeeeee;
    border-color: #bbbbbb;
    border-radius: 0;
    &:focus,
    &.focus {
      color: #000;
      background-color: #d5d5d5;
      border-color: #7c7c7c;
    }
    &:hover,
    &:active,
    &.active {
      color: #000;
      background-color: #d5d5d5;
      border-color: #9d9d9d;
    }
    &:active:hover,
    &.active:hover,
    &:active:focus,
    &.active:focus,
    &:active.focus,
    &.active.focus {
      color: #000;
      background-color: #c3c3c3;
      border-color: #7c7c7c;
    }
    &.disabled:hover,
    &[disabled]:hover {
      background-color: #eeeeee;
      border-color: #bbbbbb;
    }
  }
}

fieldset[disabled] .datepicker table tr td.range:hover {
  background-color: #eeeeee;
  border-color: #bbbbbb;
}

.datepicker table tr td.range {
  &.disabled:focus,
  &[disabled]:focus {
    background-color: #eeeeee;
    border-color: #bbbbbb;
  }
}

fieldset[disabled] .datepicker table tr td.range:focus {
  background-color: #eeeeee;
  border-color: #bbbbbb;
}

.datepicker table tr td.range {
  &.disabled.focus,
  &[disabled].focus {
    background-color: #eeeeee;
    border-color: #bbbbbb;
  }
}

fieldset[disabled] .datepicker table tr td.range.focus {
  background-color: #eeeeee;
  border-color: #bbbbbb;
}

.datepicker table tr td.range {
  &.focused {
    background: #d5d5d5;
  }
  &.disabled {
    background: #eeeeee;
    color: #777777;
    &:active {
      background: #eeeeee;
      color: #777777;
    }
  }
  &.highlighted {
    color: #000;
    background-color: #e4eef3;
    border-color: #9dc1d3;
    &:focus,
    &.focus {
      color: #000;
      background-color: #c1d7e3;
      border-color: #4b88a6;
    }
    &:hover,
    &:active,
    &.active {
      color: #000;
      background-color: #c1d7e3;
      border-color: #73a6c0;
    }
    &:active:hover,
    &.active:hover,
    &:active:focus,
    &.active:focus,
    &:active.focus,
    &.active.focus {
      color: #000;
      background-color: #a8c8d8;
      border-color: #4b88a6;
    }
    &.disabled:hover,
    &[disabled]:hover {
      background-color: #e4eef3;
      border-color: #9dc1d3;
    }
  }
}

fieldset[disabled] .datepicker table tr td.range.highlighted:hover {
  background-color: #e4eef3;
  border-color: #9dc1d3;
}

.datepicker table tr td.range.highlighted {
  &.disabled:focus,
  &[disabled]:focus {
    background-color: #e4eef3;
    border-color: #9dc1d3;
  }
}

fieldset[disabled] .datepicker table tr td.range.highlighted:focus {
  background-color: #e4eef3;
  border-color: #9dc1d3;
}

.datepicker table tr td.range.highlighted {
  &.disabled.focus,
  &[disabled].focus {
    background-color: #e4eef3;
    border-color: #9dc1d3;
  }
}

fieldset[disabled] .datepicker table tr td.range.highlighted.focus {
  background-color: #e4eef3;
  border-color: #9dc1d3;
}

.datepicker table tr td.range {
  &.highlighted {
    &.focused {
      background: #c1d7e3;
    }
    &.disabled {
      background: #e4eef3;
      color: #777777;
      &:active {
        background: #e4eef3;
        color: #777777;
      }
    }
  }
  &.today {
    color: #000;
    background-color: #f7ca77;
    border-color: #f1a417;
    &:focus,
    &.focus {
      color: #000;
      background-color: #f4b747;
      border-color: #815608;
    }
    &:hover,
    &:active,
    &.active {
      color: #000;
      background-color: #f4b747;
      border-color: #bf800c;
    }
    &:active:hover,
    &.active:hover,
    &:active:focus,
    &.active:focus,
    &:active.focus,
    &.active.focus {
      color: #000;
      background-color: #f2aa25;
      border-color: #815608;
    }
    &.disabled:hover,
    &[disabled]:hover {
      background-color: #f7ca77;
      border-color: #f1a417;
    }
  }
}

fieldset[disabled] .datepicker table tr td.range.today:hover {
  background-color: #f7ca77;
  border-color: #f1a417;
}

.datepicker table tr td.range.today {
  &.disabled:focus,
  &[disabled]:focus {
    background-color: #f7ca77;
    border-color: #f1a417;
  }
}

fieldset[disabled] .datepicker table tr td.range.today:focus {
  background-color: #f7ca77;
  border-color: #f1a417;
}

.datepicker table tr td.range.today {
  &.disabled.focus,
  &[disabled].focus {
    background-color: #f7ca77;
    border-color: #f1a417;
  }
}

fieldset[disabled] .datepicker table tr td.range.today.focus {
  background-color: #f7ca77;
  border-color: #f1a417;
}

.datepicker table tr td {
  &.range.today.disabled {
    background: #f7ca77;
    color: #777777;
    &:active {
      background: #f7ca77;
      color: #777777;
    }
  }
  &.selected {
    color: #fff;
    background-color: #777777;
    border-color: #555555;
    text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
    &.highlighted {
      color: #fff;
      background-color: #777777;
      border-color: #555555;
      text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
    }
    &:focus,
    &.highlighted:focus,
    &.focus,
    &.highlighted.focus {
      color: #fff;
      background-color: #5e5e5e;
      border-color: #161616;
    }
    &:hover,
    &.highlighted:hover,
    &:active,
    &.highlighted:active,
    &.active,
    &.highlighted.active {
      color: #fff;
      background-color: #5e5e5e;
      border-color: #373737;
    }
    &:active:hover,
    &.highlighted:active:hover,
    &.active:hover,
    &.highlighted.active:hover,
    &:active:focus,
    &.highlighted:active:focus,
    &.active:focus,
    &.highlighted.active:focus,
    &:active.focus,
    &.highlighted:active.focus,
    &.active.focus,
    &.highlighted.active.focus {
      color: #fff;
      background-color: #4c4c4c;
      border-color: #161616;
    }
    &.disabled:hover,
    &.highlighted.disabled:hover,
    &[disabled]:hover,
    &.highlighted[disabled]:hover {
      background-color: #777777;
      border-color: #555555;
    }
  }
}

fieldset[disabled] .datepicker table tr td.selected {
  &:hover,
  &.highlighted:hover {
    background-color: #777777;
    border-color: #555555;
  }
}

.datepicker table tr td.selected {
  &.disabled:focus,
  &.highlighted.disabled:focus,
  &[disabled]:focus,
  &.highlighted[disabled]:focus {
    background-color: #777777;
    border-color: #555555;
  }
}

fieldset[disabled] .datepicker table tr td.selected {
  &:focus,
  &.highlighted:focus {
    background-color: #777777;
    border-color: #555555;
  }
}

.datepicker table tr td.selected {
  &.disabled.focus,
  &.highlighted.disabled.focus,
  &[disabled].focus,
  &.highlighted[disabled].focus {
    background-color: #777777;
    border-color: #555555;
  }
}

fieldset[disabled] .datepicker table tr td.selected {
  &.focus,
  &.highlighted.focus {
    background-color: #777777;
    border-color: #555555;
  }
}

.datepicker table tr td.active {
  color: #fff;
  background-color: #337ab7;
  border-color: #2e6da4;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
  &.highlighted {
    color: #fff;
    background-color: #337ab7;
    border-color: #2e6da4;
    text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
  }
  &:focus,
  &.highlighted:focus,
  &.focus,
  &.highlighted.focus {
    color: #fff;
    background-color: #286090;
    border-color: #122b40;
  }
  &:hover,
  &.highlighted:hover,
  &:active,
  &.highlighted:active,
  &.active,
  &.highlighted.active {
    color: #fff;
    background-color: #286090;
    border-color: #204d74;
  }
  &:active:hover,
  &.highlighted:active:hover,
  &.active:hover,
  &.highlighted.active:hover,
  &:active:focus,
  &.highlighted:active:focus,
  &.active:focus,
  &.highlighted.active:focus,
  &:active.focus,
  &.highlighted:active.focus,
  &.active.focus,
  &.highlighted.active.focus {
    color: #fff;
    background-color: #204d74;
    border-color: #122b40;
  }
  &.disabled:hover,
  &.highlighted.disabled:hover,
  &[disabled]:hover,
  &.highlighted[disabled]:hover {
    background-color: #337ab7;
    border-color: #2e6da4;
  }
}

fieldset[disabled] .datepicker table tr td.active {
  &:hover,
  &.highlighted:hover {
    background-color: #337ab7;
    border-color: #2e6da4;
  }
}

.datepicker table tr td.active {
  &.disabled:focus,
  &.highlighted.disabled:focus,
  &[disabled]:focus,
  &.highlighted[disabled]:focus {
    background-color: #337ab7;
    border-color: #2e6da4;
  }
}

fieldset[disabled] .datepicker table tr td.active {
  &:focus,
  &.highlighted:focus {
    background-color: #337ab7;
    border-color: #2e6da4;
  }
}

.datepicker table tr td.active {
  &.disabled.focus,
  &.highlighted.disabled.focus,
  &[disabled].focus,
  &.highlighted[disabled].focus {
    background-color: #337ab7;
    border-color: #2e6da4;
  }
}

fieldset[disabled] .datepicker table tr td.active {
  &.focus,
  &.highlighted.focus {
    background-color: #337ab7;
    border-color: #2e6da4;
  }
}

.datepicker table tr td span {
  display: block;
  width: 23%;
  height: 54px;
  line-height: 54px;
  float: left;
  margin: 1%;
  cursor: pointer;
  border-radius: 4px;
  &:hover,
  &.focused {
    background: #eeeeee;
  }
  &.disabled {
    background: none;
    color: #777777;
    cursor: default;
    &:hover {
      background: none;
      color: #777777;
      cursor: default;
    }
  }
  &.active {
    color: #fff;
    background-color: #337ab7;
    border-color: #2e6da4;
    text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
    &:hover {
      color: #fff;
      background-color: #337ab7;
      border-color: #2e6da4;
      text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
    }
    &.disabled {
      color: #fff;
      background-color: #337ab7;
      border-color: #2e6da4;
      text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
      &:hover {
        color: #fff;
        background-color: #337ab7;
        border-color: #2e6da4;
        text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
      }
    }
    &:focus,
    &:hover:focus {
      color: #fff;
      background-color: #286090;
      border-color: #122b40;
    }
    &.disabled {
      &:focus,
      &:hover:focus {
        color: #fff;
        background-color: #286090;
        border-color: #122b40;
      }
    }
    &.focus,
    &:hover.focus {
      color: #fff;
      background-color: #286090;
      border-color: #122b40;
    }
    &.disabled {
      &.focus,
      &:hover.focus {
        color: #fff;
        background-color: #286090;
        border-color: #122b40;
      }
    }
    &:hover,
    &.disabled:hover,
    &:active,
    &:hover:active {
      color: #fff;
      background-color: #286090;
      border-color: #204d74;
    }
    &.disabled {
      &:active,
      &:hover:active {
        color: #fff;
        background-color: #286090;
        border-color: #204d74;
      }
    }
    &.active,
    &:hover.active {
      color: #fff;
      background-color: #286090;
      border-color: #204d74;
    }
    &.disabled {
      &.active,
      &:hover.active {
        color: #fff;
        background-color: #286090;
        border-color: #204d74;
      }
    }
    &:active:hover,
    &.disabled:active:hover,
    &.active:hover,
    &.disabled.active:hover,
    &:active:focus,
    &:hover:active:focus {
      color: #fff;
      background-color: #204d74;
      border-color: #122b40;
    }
    &.disabled {
      &:active:focus,
      &:hover:active:focus {
        color: #fff;
        background-color: #204d74;
        border-color: #122b40;
      }
    }
    &.active:focus,
    &:hover.active:focus {
      color: #fff;
      background-color: #204d74;
      border-color: #122b40;
    }
    &.disabled {
      &.active:focus,
      &:hover.active:focus {
        color: #fff;
        background-color: #204d74;
        border-color: #122b40;
      }
    }
    &:active.focus,
    &:hover:active.focus {
      color: #fff;
      background-color: #204d74;
      border-color: #122b40;
    }
    &.disabled {
      &:active.focus,
      &:hover:active.focus {
        color: #fff;
        background-color: #204d74;
        border-color: #122b40;
      }
    }
    &.active.focus,
    &:hover.active.focus {
      color: #fff;
      background-color: #204d74;
      border-color: #122b40;
    }
    &.disabled {
      &.active.focus {
        color: #fff;
        background-color: #204d74;
        border-color: #122b40;
      }
      &:hover {
        &.active.focus {
          color: #fff;
          background-color: #204d74;
          border-color: #122b40;
        }
        background-color: #337ab7;
        border-color: #2e6da4;
      }
    }
    &[disabled]:hover,
    &.disabled[disabled]:hover {
      background-color: #337ab7;
      border-color: #2e6da4;
    }
  }
}

fieldset[disabled] .datepicker table tr td span.active {
  &:hover,
  &.disabled:hover {
    background-color: #337ab7;
    border-color: #2e6da4;
  }
}

.datepicker table tr td span.active {
  &.disabled:focus,
  &:hover.disabled:focus {
    background-color: #337ab7;
    border-color: #2e6da4;
  }
  &.disabled {
    &.disabled:focus,
    &:hover.disabled:focus {
      background-color: #337ab7;
      border-color: #2e6da4;
    }
  }
  &[disabled]:focus,
  &:hover[disabled]:focus {
    background-color: #337ab7;
    border-color: #2e6da4;
  }
  &.disabled {
    &[disabled]:focus,
    &:hover[disabled]:focus {
      background-color: #337ab7;
      border-color: #2e6da4;
    }
  }
}

fieldset[disabled] .datepicker table tr td span.active {
  &:focus,
  &:hover:focus {
    background-color: #337ab7;
    border-color: #2e6da4;
  }
  &.disabled {
    &:focus,
    &:hover:focus {
      background-color: #337ab7;
      border-color: #2e6da4;
    }
  }
}

.datepicker table tr td span.active {
  &.disabled.focus,
  &:hover.disabled.focus {
    background-color: #337ab7;
    border-color: #2e6da4;
  }
  &.disabled {
    &.disabled.focus,
    &:hover.disabled.focus {
      background-color: #337ab7;
      border-color: #2e6da4;
    }
  }
  &[disabled].focus,
  &:hover[disabled].focus {
    background-color: #337ab7;
    border-color: #2e6da4;
  }
  &.disabled {
    &[disabled].focus,
    &:hover[disabled].focus {
      background-color: #337ab7;
      border-color: #2e6da4;
    }
  }
}

fieldset[disabled] .datepicker table tr td span.active {
  &.focus,
  &:hover.focus {
    background-color: #337ab7;
    border-color: #2e6da4;
  }
  &.disabled {
    &.focus,
    &:hover.focus {
      background-color: #337ab7;
      border-color: #2e6da4;
    }
  }
}

.datepicker {
  table tr td span {
    &.old,
    &.new {
      color: #777777;
    }
  }
  .datepicker-switch {
    width: 145px;
    cursor: pointer;
  }
  .prev,
  .next,
  tfoot tr th {
    cursor: pointer;
  }
  .datepicker-switch:hover,
  .prev:hover,
  .next:hover,
  tfoot tr th:hover {
    background: #eeeeee;
  }
  .prev.disabled,
  .next.disabled {
    visibility: hidden;
  }
  .cw {
    font-size: 10px;
    width: 12px;
    padding: 0 2px 0 5px;
    vertical-align: middle;
  }
}

.input-group.date .input-group-addon {
  cursor: pointer;
}

.input-daterange {
  width: 100%;
  input {
    text-align: center;
    &:first-child {
      border-radius: 3px 0 0 3px;
    }
    &:last-child {
      border-radius: 0 3px 3px 0;
    }
  }
  .input-group-addon {
    width: auto;
    min-width: 16px;
    padding: 4px 5px;
    line-height: 1.42857143;
    border-width: 1px 0;
    margin-left: -5px;
    margin-right: -5px;
  }
}
