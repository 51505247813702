// Ocean colors for the main branding of fishisfast
$blue-200: #dfe6ea;
$blue-300: #b0dfe7;
$blue-400: #7fd2dd;
$blue-500: #47bcde;
$blue-600: #1ea4d7;
$blue-700: #0075be;
$blue-800: #005b97;
$blue-900: #042d49;

// Gold colors of the alternate branding of fishisfast
$gold-200: #fcf8ec;
$gold-300: #faf2d8;
$gold-400: #eadcb2;
$gold-500: #d9c58d;
$gold-600: #ccb470;
$gold-700: #bea563;
$gold-800: #af9656;
$gold-900: #675d43;

// Main colors for CSS
$blue: $blue-600;
$indigo: #6610f2;
$purple: #6f42c1;
$pink: #e83e8c;
$red: #d14562;
$orange: #fd7e14;
$yellow: $gold-300;
$green: #1daf49;
$teal: #32b397;
$cyan: $blue-400;

// Main shades for all CSS
$white: hsl(213, 35%, 100%);
$gray-100: hsl(213, 35%, 98%);
$gray-200: hsl(213, 35%, 93%);
$gray-300: hsl(213, 35%, 89%);
$gray-400: hsl(213, 35%, 83%);
$gray-500: hsl(213, 35%, 71%);
$gray-600: hsl(213, 35%, 56%);
$gray-700: hsl(213, 35%, 31%);
$gray-800: hsl(213, 35%, 23%);
$gray-900: hsl(213, 35%, 15%);
$black: hsl(213, 35%, 0%);

// function to make common names
$theme-colors: (
  primary: $blue,
  secondary: $gray-600,
  success: $green,
  info: $cyan,
  warning: $yellow,
  danger: $red,
  light: $gray-100,
  dark: $blue-900,
);

// Body bg and color
$body-bg: $gray-200;
$body-color: $gray-600;

// Fonts
$font-family-sans-serif: 'Proxima Nova W07', 'Proxima Nova', 'Noto Sans',
  'Cairo', 'Noto Sans JP', 'Nanum Gothic', 'BPG 2017 DejaVu Sans', -apple-system,
  BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
  sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
  'Noto Color Emoji';

// Theme hacks
$tooltip-color: $gold-800;
$tooltip-bg: $gold-200;
$modal-inner-padding: 1rem;
$modal-footer-margin-between: 0.5rem;
$popover-bg: $gold-200;
$popover-border-color: $gold-300;
$popover-box-shadow: 0 7px 14px rgba(50, 50, 93, 0.1),
  0 3px 6px rgba(0, 0, 0, 0.08);
$popover-header-bg: $gold-300;
$popover-header-color: $gold-800;
$popover-body-color: $gold-800;
$custom-select-bg: $gray-100;
$input-group-addon-bg: $gray-100;
$input-bg: $gray-100;
$btn-font-weight: 600;
$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 1150px,
  xl: 1400px,
);
