// Make select2 look like Bootstrap's .custom-select
.select2-container--bootstrap4 .select2-selection {
  background: $input-bg;
}

.item-tag {
  vertical-align: middle;
}

// FIXME: simple_form not working with input-group
.input-group > div.field_with_errors {
  position: relative;
  flex: 1 1 auto;
  width: 1%;
  margin-bottom: 0;

  &:not(:first-child) .form-control {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }

  &:not(:last-child) .form-control {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
}

// Hack input width
label.collection_radio_buttons {
  width: 100%;
}

/* Hide spinners in numeric input fields */
.no-spinners::-webkit-outer-spin-button,
.no-spinners::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.no-spinners {
  -moz-appearance: textfield;
}
